import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data-service/data.service';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { AppState } from 'src/app/state';
import { fillCatCompetencia, photoLoading, quantidadeCompetencias } from 'src/app/state/state.actions';

@Component({
	selector: 'app-perfilv2',
	templateUrl: './perfilv2.component.html',
	styleUrls: ['./perfilv2.component.scss'],
})
export class Perfilv2Component implements OnInit, OnDestroy {
	@Input() backgroundImg;
	subscriptions: Subscription[] = [];
	filtereds = [];
	loading = false;
	isTextHidden = true;
	showHide = false;
	catFormacao = [];
	catCompetencia = [];
	catIdioma = [];
	catExperiencia = [];
	catPerfil = [];
	limitComp = 5;
	type: string;
	editModecatIdioma = false;
	editModecatCompetencia = false;
	editModecatExperiencia = false;
	editModecatFormacao = false;
	editModeUserData = false;
	editModeUserSocialMedia = false;
	typeEdition: any;
	uploadPhoto: FormData;
	photoName: string;
	showAllCompetencias = false;
	showContent: boolean;
	state: any;
	user: any;
	arrNivelEnsino = ['Graduação', 'Pós-Graduação', 'Curso Livre', 'Corporativo', 'Extensão'];
	idiomasArray = ['Português', 'Inglês', 'Espanhol', 'Francês', 'Alemão', 'Italiano', 'Chinês', 'Japonês'];
	fluenciaArray = ['Básico', 'Intermediário', 'Avançado', 'Fluente'];
	socialMedia = ['linkedin', 'twitter', 'facebook', 'instagram'];
	sistema_configs: any = {
		sistema_certificados: false,
	};
	quantidadeDeCompetencias = 0;

	constructor(
		private store: Store<AppState>,
		private dataService: DataService,
		public global: GlobalService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.getState();
		this.setBackground();
		this.filteredCompetencias();
		this.getSystemaCertificadosConfig();
		this.dataService.getCurriculoAluno();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.checkLineClamp();
		}, 500);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	getState() {
		const subscription = this.store
			.select((state: any) => {
				const {
					dadosEmpresa,
					dadosPolo,
					usuario,
					projetosAluno,
					showingMenu,
					modalVideo,
					casosSucesso,
					softSkills,
					eventos,
					catFormacao,
					catCompetencia,
					catExperiencia,
					catIdioma,
					catPerfil,
					selectedCurriculumProfile,
					photoLoading,
				} = state.newAppState;
				this.state = {
					dadosEmpresa,
					dadosPolo,
					usuario,
					projetosAluno,
					showingMenu,
					modalVideo,
					casosSucesso,
					softSkills,
					eventos,
					catFormacao,
					catCompetencia,
					catExperiencia,
					catIdioma,
					catPerfil,
					selectedCurriculumProfile,
					photoLoading,
				};

				if (this.state && this.state.catCompetencia !== undefined) {
					Object.keys(this.state).forEach((key) => {
						if (key.startsWith('cat') && !this[key].length) {
							this.state[key]?.forEach((element) => {
								this[key].push(Object.assign({}, element));
							});
						}
					});
					this.catCompetencia = JSON.parse(JSON.stringify(this.state.catCompetencia));
					this.quantidadeDeCompetencias = this.catCompetencia.length;
				}
			})
			.subscribe();

		this.subscriptions.push(subscription);
	}

	setBackground() {
		if (this.state.dadosPolo && this.state.dadosPolo.banner_perfil)
			this.backgroundImg = this.global.generateImageURL(this.state.dadosPolo.banner_perfil);
		else
			this.backgroundImg = this.state.dadosEmpresa?.banner_perfil
				? this.global.generateImageURL(this.state.dadosEmpresa.banner_perfil)
				: this.global.generateImageURL('../../assets/imgs/background-perfil.jpg');
	}

	goToSocialMedia(urlSocialMedia: string, type: string) {
		if (!urlSocialMedia) return;
		window.open(urlSocialMedia, type);
	}

	setUserData(bln) {
		this.editModeUserData = bln;
		this.user = this.deep(this.state.usuario);
	}

	editSocialMedia(bln) {
		this.editModeUserSocialMedia = bln;
		this.user = this.deep(this.state.usuario);
	}

	getSystemaCertificadosConfig() {
		const subscription = this.dataService
			.getSistemaConfigs(41)
			.pipe(tap((res) => (this.sistema_configs.sistema_certificados = res.valor_booleano)))
			.subscribe();
		this.subscriptions.push(subscription);
	}

	checkIfAprovado(competencia) {
		const aprovado =
			competencia?.projetoTurmaEstudante?.id_projeto_turma_estudante_status === 5 && competencia?.valor === 100;

		return aprovado;
	}

	checkIfHasSignature(competencia) {
		if (!competencia) return false;

		if (
			competencia.projeto?.empresa?.imagem_assinatura &&
			competencia.projeto?.empresa?.nome_assinatura &&
			competencia.projeto?.empresa?.imagem_logo_certificado
		)
			return true;

		return false;
	}

	emitCertified(competencia: any) {
		this.loading = true;
		const subscription = this.dataService
			.getCertificadoGenerate(competencia.id_competencia)
			.pipe(
				tap((response) => {
					const newCatCompetencia = this.state.catCompetencia.map((catCompetencia) => ({ ...catCompetencia }));
					const competenciaSelecionada = newCatCompetencia.find(
						(c) => c.id_usuario_competencia === competencia.id_usuario_competencia
					);
					competenciaSelecionada.certificado_url = response.certificado_url;

					this.store.dispatch(fillCatCompetencia({ payload: newCatCompetencia }));
					this.global.toast(this.translate.instant('GENERAL.SUCCESS'), response.message);
				}),
				tap((response) => {
					window.open(response.certificado_url, '_blank');
				}),
				catchError((error) => {
					console.log(error);
					this.global.toast(this.translate.instant('GENERAL.ERROR'), error.error.message);
					return error;
				}),
				finalize(() => (this.loading = false))
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	downloadCertified(competencia: any) {
		window.open(competencia.certificado_url, '_blank');
	}

	filteredCompetencias() {
		const filtered = [];
		if (this.state.usuario.usuarioCompetencia && this.state.usuario.usuarioCompetencia.length > 0) {
			this.state.usuario.usuarioCompetencia.forEach((competencia) => {
				if (competencia.favorita) filtered.push(competencia);
			});
			this.store.dispatch(
				quantidadeCompetencias({
					payload: this.state.usuario.usuarioCompetencia.length,
				})
			);
		} else this.store.dispatch(quantidadeCompetencias({ payload: 0 }));
		this.filtereds = filtered;
	}

	setPerfilEdition(bln: boolean, typeEdition: string, index?: number) {
		this.typeEdition = typeEdition;
		if (typeEdition === 'catCompetencia') {
			this.editModeCompetencias(true);
		} else {
			this[`editMode${typeEdition}`] = bln;
			if (index !== undefined) {
				this[typeEdition][index].editing = bln;
			} else {
				this[`toggle${typeEdition}`]();
			}
		}
	}

	deep(value) {
		if (typeof value !== 'object' || value === null) return value;
		if (Array.isArray(value)) return this.deepArray(value);
		return this.deepObject(value);
	}

	deepArray<T extends any[]>(collection: T) {
		return collection.map((value) => {
			return this.deep(value);
		});
	}

	deepObject<T>(source: T) {
		const result = {};
		Object.keys(source).forEach((key) => {
			const value = source[key];
			result[key] = this.deep(value);
		}, {});
		return result as T;
	}

	toggleText() {
		this.isTextHidden = !this.isTextHidden;
	}

	checkLineClamp() {
		const elements = document.getElementsByClassName('aboutMe');
		if (elements.length && elements[0].clientHeight > 100) this.showHide = !this.showHide;
	}

	saveData(type: string) {
		if (
			this.state.projetosAluno?.length > 0 &&
			this.state.projetosAluno[0].estudante.id_projeto_turma_estudante_status === 8
		)
			return throwError('No permission');

		const requiredFields = ['nome_social', 'celular', 'email_pessoal'];

		if (type === 'curriculo') {
			requiredFields.forEach((field) => {
				if (this.user[field] === '')
					this.global.toast(this.translate.instant('GENERAL.ERROR'), this.translate.instant('CURRICULO.EMPTY_FIELDS'));
				return;
			});
		}
		const catGen = this[`cat${type.charAt(0).toUpperCase() + type.slice(1)}`];
		if (catGen && catGen.length > 0) {
			let hasEmptyField = false;
			for (let i = catGen.length - 1; i >= 0; i--) {
				hasEmptyField = Object.values(catGen[i]).some((x) => x === '');
				if (hasEmptyField) {
					this.global.toast(this.translate.instant('GENERAL.ERROR'), this.translate.instant('CURRICULO.EMPTY_FIELDS'));
					return;
				}
			}
		}
		this.clearIdsAndEditings();
		const sendData = {
			usuario: {
				id_usuario: this.state.usuario.id_usuario,
				nome_social: this.state.usuario.nome_social,
				celular: this.state.usuario.celular,
				telefone: this.state.usuario.telefone,
				email_pessoal: this.state.usuario.email_pessoal,
				rede_linkedin: this.state.usuario.rede_linkedin,
				rede_twitter: this.state.usuario.rede_twitter,
				rede_facebook: this.state.usuario.rede_facebook,
				rede_instagram: this.state.usuario.rede_instagram,
				rede_skype: this.state.usuario.rede_skype,
				curriculo: {
					id_curriculo_usuario: this.state.usuario.curriculoUsuario[0].id_curriculo_usuario,
					id_usuario: this.state.usuario.id_usuario,
					sobremim: this.state.usuario.curriculoUsuario[0].sobremim,
					interesses: this.state.usuario.curriculoUsuario[0].interesses,
					formacao: this.state.catFormacao,
					idioma: this.state.catIdioma,
					experiencia: this.state.catExperiencia,
					competencia: this.state.catCompetencia,
					perfil: this.state.catPerfil,
				},
			},
		};
		switch (type) {
		case 'curriculo':
			sendData.usuario.nome_social = this.user.nome_social;
			sendData.usuario.email_pessoal = this.user.email_pessoal;
			sendData.usuario.curriculo.sobremim = this.user.curriculoUsuario[0].sobremim;
			sendData.usuario.celular = this.user.celular;
			sendData.usuario.telefone = this.user.telefone;
			break;
		case 'socialMedia':
			sendData.usuario.rede_facebook = this.user.rede_facebook;
			sendData.usuario.rede_instagram = this.user.rede_instagram;
			sendData.usuario.rede_linkedin = this.user.rede_linkedin;
			sendData.usuario.rede_twitter = this.user.rede_twitter;
			break;
		case 'formacao':
			sendData.usuario.curriculo.formacao = this.catFormacao;
			break;
		case 'idioma':
			sendData.usuario.curriculo.idioma = this.catIdioma;
			break;
		case 'experiencia':
			sendData.usuario.curriculo.experiencia = this.catExperiencia;
			break;
		case 'competencia':
			sendData.usuario.curriculo.competencia = this.catCompetencia;
			break;
		case 'perfil':
			sendData.usuario.curriculo.perfil = this.catPerfil;
			break;
		default:
			break;
		}
		this.dataService.updateCurriculo(sendData).then(
			() => {
				this.global.toast(this.translate.instant('GENERAL.SUCCESS'), this.translate.instant('CURRICULO.UPDATED'));
				this.dataService.refreshUserData();
			},
			(error) => {
				this.dataService.refreshUserData();
				this.global.toast(this.translate.instant('GENERAL.ERROR'), error.error.message);
			}
		);
	}

	callInput() {
		const input: HTMLElement = document.querySelector('.upload-picture');
		input.click();
	}

	prepareUpload(event) {
		this.uploadPhoto = new FormData();
		if (event && event.target.files && event.target.files[0]) {
			this.uploadPhoto.append('foto', event.target.files[0], event.target.files[0].name);
			this.photoName = event.target.files[0].name;
			this.uploadPicture();
		}
	}

	uploadPicture() {
		this.store.dispatch(photoLoading({ payload: true }));
		this.dataService.changePicture(this.uploadPhoto);
	}

	togglecatFormacao(index = null) {
		if (index == null) {
			this.catFormacao.push({
				curso: '',
				data_inclusao: null,
				instituicao: '',
				nivel_ensino: '',
				valor_progresso: 100,
				status_progresso: 'Finalizado',
				editing: true,
			});
			this.catFormacao = this.catFormacao.slice();
		} else this.catFormacao.splice(index, 1);
	}

	togglecatIdioma(index = null) {
		if (index == null) {
			this.catIdioma.push({
				fluencia: '',
				idioma: '',
				editing: true,
			});
		} else this.catIdioma.splice(index, 1);
	}

	togglecatExperiencia(index = null) {
		if (index == null) {
			this.catExperiencia.push({
				cargo: '',
				corporacao: '',
				data_final: '',
				data_inicio: '',
				editing: true,
			});
		} else this.catExperiencia.splice(index, 1);
	}

	editModeCompetencias(value = false) {
		this.editModecatCompetencia = value;
		if (value) {
			if (!this.showAllCompetencias) {
				this.showAllCompetencias = true;
				this.limitComp = 10000000;
			}
		} else {
			this.toggleShowAllCompetencias();
		}
	}

	toggleShowAllCompetencias() {
		this.showAllCompetencias = !this.showAllCompetencias;
		if (this.showAllCompetencias) this.limitComp = 10000000;
		else this.limitComp = 5;
	}

	toggleFavorita(competencia) {
		this.catCompetencia.forEach((temp) => {
			if (temp.id_usuario_competencia === competencia.id_usuario_competencia) temp.favorita = !temp.favorita;
		});

		this.store.dispatch(fillCatCompetencia({ payload: this.catCompetencia }));
	}

	clearIdsAndEditings() {
		this.catFormacao.forEach((formacao) => {
			delete formacao.id_nivel_ensino;
			delete formacao.editing;
		});
		this.catIdioma.forEach((idioma) => {
			delete idioma.id_idioma_fluencia;
			delete idioma.id_idioma;
			delete idioma.editing;
		});
		this.catExperiencia.forEach((experiencia) => {
			delete experiencia.editing;
		});
		this.showAllCompetencias = false;
		this.editModecatCompetencia = false;
		this.limitComp = 5;
		this.editModecatExperiencia = false;
		this.editModecatFormacao = false;
		this.editModecatIdioma = false;
		this.editModeUserData = false;
		this.editModeUserSocialMedia = false;
	}

	toggleContent() {
		this.showContent = !this.showContent;
	}

	cancelEditData(type: string) {
		if (type === 'curriculo') {
			this.setUserData(false);
		} else if (type === 'catCompetencia') {
			this.editModeCompetencias(false);
		} else if (type === 'socialMedia') {
			this.editSocialMedia(false);
		} else {
			this[type] = this.deep(this.state[type]);
			for (let i = this[type].length - 1; i >= 0; i--) {
				this[type][i].editing = false;
				if (this[type][i] && !this[type][i].id_curriculo_usuario) this[type] = this[type].slice(0, i);
			}
			this[`editMode${type}`] = false;
		}
	}
}
